import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { fetchStatuses, fetchUsers, fetchDepartments, getPriorities, getTags, getTypes, resetChangedColumns } from "../../../../redux/actions";
import { colorOption, colorValue, colorValueSingle, imageOption } from '../../../SelectComponents';
import { ucFirst } from "../../../../helpers/generic";
import { Form } from "react-bootstrap";
import failAlert from "../../../../helpers/failAlert";
import { baseURL } from "../../../../helpers/baseURL";
import { headers } from "../../../../helpers/Requests";
import successAlert from "../../../../helpers/successAlert";
import DatePicker from "../../../../helpers/DatePicker";
import ToastAlert from "../../../../helpers/ToastAlert";

const TicketProperties = ({ ticket, ticketIsLoading, onComplete }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState({});

    const updatedTaskSelector = useSelector(state => {
        return state?.UpdatesTaskReducer;
    });

    useEffect(() => {
        var changedColumnsTaskSelector = updatedTaskSelector?.changedColumns;
        // console.log('changedColumnsTaskSelector::',changedColumnsTaskSelector);
        let changedColumnsToWatch = ["type_id", "priority_id", "status_id", "department_id", "due_date", "tags", "user_id"];
        let formateColumnsToWatch = ["Type", "Priority", "Status", "Department", "Due Date", "Tags", "Assignee"];

        if(changedColumnsToWatch.some(key => changedColumnsTaskSelector[key])){
            let filteredChangedColumns = Object.keys(changedColumnsTaskSelector).filter(key => changedColumnsToWatch.includes(key)).map((item)=>{
                const index = changedColumnsToWatch.indexOf(item);
                return formateColumnsToWatch[index];
            });
            onComplete && onComplete();
            dispatch(resetChangedColumns());
            // successAlert(`${filteredChangedColumns.join(', ')} updated by ${updatedTaskSelector?.changedBy || 'System'}`);
            ToastAlert({
                text: `${filteredChangedColumns.join(', ')} updated by ${updatedTaskSelector?.changedBy || 'System'}`,
            });
        }
    },[updatedTaskSelector]);

	const dataKey = {
		'status': 'statuses',
		'department': 'departments',
		'type': 'types',
		'priority': 'priorities',
		'tag': 'tags',
		'assignee': 'users',
	}

	const data = {
		statuses: useSelector(state => state.statusesReducer.statuses),
		departments: useSelector(state => state.departmentsReducer.departments),
		priorities: useSelector(state => state.prioritiesReducer.priorities),
		types: useSelector(state => state.typesReducer.types),
		tags: useSelector(state => state.tagsReducer.tags),
		users: useSelector(state => state.usersReducer.users),
	}

	const dataFetchers = {
		statuses: fetchStatuses,
		departments: fetchDepartments,
		priorities: getPriorities,
		types: getTypes,
		tags: getTags,
		users: fetchUsers,
	};

	useEffect(() => {
		Object.keys(dataFetchers).forEach(key => {
			if (!isLoading[key] && data[key].length === 0) {
				dispatch(dataFetchers[key]());
				updateIsLoading(key, true)
			}
		});
	}, [dispatch, isLoading]);

	const updateIsLoading = (item, value) => {
		setIsLoading(prev => ({
			...prev,
			[item]: value
		}));
	};

	const updateProperties = async (item, updateData) => {
		updateIsLoading(item, true);
		try {
			const response = await axios.patch(`${baseURL}ticket-update/${ticket.id}`, {
				[item]: updateData
			}, headers);
			successAlert(response.data.message);
            onComplete && onComplete();
			return response.data;
		} catch (error) {
			failAlert(error.response?.data?.message || 'Something went wrong. Please try again later.');
		} finally {
			updateIsLoading(item, false);
		}
	};

	const handleChange = async (item, selectedValue) => {
		const updateData = (item === 'tag') ? selectedValue.map(tag => tag.value) : (selectedValue?.value || null);
		await updateProperties(item, updateData);
	};

	const parseDate = (value) => {
		const date = new Date(value);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return { value: `${year}-${month}-${day}` };
	}

	const predefinedDates = [
		{
			label: 'Today',
			value: new Date()
		}, {
			label: 'Tomorrow',
			value: new Date(new Date().setDate(new Date().getDate() + 1))
		}, {
			label: 'Next Week',
			value: new Date(new Date().setDate(new Date().getDate() + 7))
		}
	]

	const getLabeledOptions = (item, value) => {
		const options = data[dataKey[item]]?.map(row => {
			let option = {
				value: row.id,
				label: ucFirst(row.name),
			};
			if (item === 'assignee') {
				option.image = row.profile_image;
				option.description = row.email;
			} else {
				option.color = row.color;
			}
			return option;
		}) || [];
		const selected = options.filter(row => {
			if (item === 'tag') {
				const ids = value.map(tag => tag.id);
				return ids.includes(row.value)
			} else {
				const id = value?.id || value || null;
				return id === row.value
			}
		});
		return { options, selected };
	};

	const ItemView = ({ item, value, className }) => {

		let options = [];
		let selected = [];
		let intermediateValue = value;

		switch (item) {
			case 'status':
			case 'department':
			case 'type':
			case 'priority':
			case 'tag':
				({ options, selected } = getLabeledOptions(item, intermediateValue));
				const isMulti = item === 'tag';
				const isClearable = ['type', 'priority', 'tag'].includes(item);
				const selectComponents = isMulti
					? { Option: colorOption, MultiValueLabel: colorValue }
					: { Option: colorOption, SingleValue: colorValueSingle };
				return (
					<Form.Group className={className}>
						<Form.Label className="mb-1">{ucFirst(item)}:</Form.Label>
						<Select
							isMulti={isMulti}
							isClearable={isClearable}
							options={options}
							defaultValue={selected}
							components={selectComponents}
							placeholder={`Select ${ucFirst(item)}`}
							onChange={(value) => handleChange(item, value)}
							classNamePrefix="select-component"
							className="flex-grow-1"
							isDisabled={ticketIsLoading || isLoading[item]}
						/>
					</Form.Group>
				);
			case 'assignee':
				({ options, selected } = getLabeledOptions(item, intermediateValue));
				return (
					<Form.Group className={className}>
						<Form.Label className="mb-1">Assignee:</Form.Label>
						<Select
							isClearable={true}
							options={options}
							defaultValue={selected}
							components={{ Option: imageOption }}
							placeholder={`Select ${ucFirst(item)}`}
							onChange={(value) => handleChange(item, value)}
							classNamePrefix="select-component"
							className="flex-grow-1"
							isDisabled={ticketIsLoading || isLoading[item]}
							filterOption={(option, input) => {
								return option.label?.toLowerCase().includes(input.toLowerCase()) ||
									   option.data?.description?.toLowerCase().includes(input.toLowerCase());
							}}
						/>
					</Form.Group>
				);
			case 'due_date':
				selected = intermediateValue ? new Date(intermediateValue) : null;
				return (
					<Form.Group className={className}>
						<Form.Label className="mb-1">Due Date:</Form.Label>
						<DatePicker
							handleDateChange={(value) => handleChange(item, parseDate(value))}
							selected={intermediateValue ? new Date(intermediateValue) : null}
						/>
					</Form.Group>
				)
			default:
				return null;
		}
	}

	return (
		<>
			<ItemView item="due_date" value={ticket.due_date} className="mb-3" />
			<ItemView item="assignee" value={ticket.assignee} className="mb-3" />
			<ItemView item="status" value={ticket.status} className="mb-3" />
			<ItemView item="department" value={ticket.department} className="mb-3" />
			<ItemView item="priority" value={ticket.priority} className="mb-3" />
			<ItemView item="type" value={ticket.type} className="mb-3" />
			<ItemView item="tag" value={ticket.tags} />
		</>
	);
};

// const MemorizedTicketProperties = useMemo(TicketProperties);

export default TicketProperties;
