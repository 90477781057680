import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import unassigned from '../../css/img/unassigned.png'

const UserBullet = ({ user, size = 35, tooltip = true, ...props }) => {
	const name = (user === undefined || user === null) ? 'unassigned' : user.name;
	const image = (user === undefined || user === null) ? unassigned : (user.profile_image ? user.profile_image : 'https://ui-avatars.com/api/?name=' + encodeURIComponent(user.name));

	const containerStyle = {
		whiteSpace: 'nowrap',
		fontSize: '.9em',
	};

	const imageStyle = {
		width: `${size}px`,
		height: `${size}px`,
		borderRadius: '50%',
		display: 'inline-block',
	};

	const labelStyle = {
		paddingLeft: '5px',
	};

	return (
		tooltip ? (
			<OverlayTrigger style={containerStyle} overlay={<Tooltip style={labelStyle}>{name}</Tooltip>} {...props}>
				<img src={image} style={imageStyle} />
			</OverlayTrigger>
		) : (
			<div style={containerStyle} {...props}>
				<img src={image} style={imageStyle} /><span style={labelStyle}>{name}</span>
			</div>
		)
	);
}

export default UserBullet;