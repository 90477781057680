import React, { useEffect, useState } from 'react'
import { BsCheck2Circle, BsFunnel, BsPlusLg, BsXLg } from 'react-icons/bs';
import { Badge, Button, Spinner, Dropdown } from 'react-bootstrap';
import styles from './TicketsFilter.module.css';
import TicketsFilterItem from './TicketsFilterItems';

const TicketsFilter = ({ data, isLoading, filters, onFilterChange }) => {

	const [showDropdown, setShowDropdown] = useState(false);
	const [intermediateFilters, setIntermediateFilters] = useState({});
	const [filterOptions, setFilterOptions] = useState({
		'text': { label: 'Keyword', visible: false, default: '' },
		'status': { label: 'Status', visible: false, default: '' },
		'department': { label: 'Department', visible: false, default: [] },
		'type': { label: 'Type', visible: false, default: [] },
		'priority': { label: 'Priority', visible: false, default: [] },
		'tag': { label: 'Tag', visible: false, default: [] },
		'assigned': { label: 'Assigned', visible: false, default: null },
		'assignee': { label: 'Assignee', visible: false, default: [] },
		'replied': { label: 'Is Replied', visible: false, default: null },
		'due_date': { label: 'Due Date', visible: false, default: '' },
		'due_date_range': { label: 'Range', visible: false, default: [] },
		'created_at': { label: 'Created Date', visible: false, default: '' },
		'created_at_range': { label: 'Range', visible: false, default: [] },
		'deleted': { label: 'Deleted', visible: false, default: null },
	});

	useEffect(() => {
		if (JSON.stringify(filters) !== JSON.stringify(intermediateFilters)) {
			setIntermediateFilters({ ...filters });
		}
	}, [filters]);

	useEffect(() => {
		updateFilterOptions();
	}, [intermediateFilters]);

	const toggleFilter = (filter, force) => {
		setFilterOptions(prevOptions => {
			const newOptions = { ...prevOptions };
			newOptions[filter] = {
				...prevOptions[filter],
				visible: force === undefined ? !prevOptions[filter].visible : force
			};

			// Clear removed filter
			if (!newOptions[filter].visible) {
				setIntermediateFilters({
					...intermediateFilters,
					[filter]: newOptions[filter].default,
				});
			}

			return newOptions;
		});
	};

	const updateFilterOptions = () => {
		setFilterOptions(prevOptions => {
			const newOptions = { ...prevOptions };
			for (const filter of Object.keys(intermediateFilters)) {
				const value = intermediateFilters[filter];
				let isVisible = value !== null && value !== '' && !(Array.isArray(value) && value.length === 0);

				if (
					(filter === 'due_date_range' && intermediateFilters['due_date'] === 'range') ||
					(filter === 'created_at_range' && intermediateFilters['created_at'] === 'range')
				) {
					isVisible = true;
				}

				newOptions[filter] = {
					...prevOptions[filter],
					visible: isVisible
				};
			}
			return newOptions;
		});
	}

	const onFilterUpdate = (filter, value) => {
		let newValue = value;
		if (value !== null && value.value !== undefined) {
			newValue = value.value;
		} else if (Array.isArray(value)) {
			newValue = value.map(v => v.value);
		}

		setIntermediateFilters({
			...intermediateFilters,
			[filter]: newValue,
		});

		// Update visibility for dependent filters
		if (filter === 'due_date' && newValue === 'range') {
			setFilterOptions(prevOptions => ({
				...prevOptions,
				due_date_range: { ...prevOptions.due_date_range, visible: true },
			}));
		}
		if (filter === 'created_at' && newValue === 'range') {
			setFilterOptions(prevOptions => ({
				...prevOptions,
				created_at_range: { ...prevOptions.created_at_range, visible: true },
			}));
		}
	};

	const applyFilters = () => {
		onFilterChange(intermediateFilters);
		setShowDropdown(false);
	}

	const clearFilters = () => {
		const defaultFilters = Object.entries(filterOptions).reduce((row, [key, value]) => {
			row[key] = value.default;
			return row;
		}, {});
		setIntermediateFilters(defaultFilters);
	}

	const VisibleFiltersCount = () => {
		let visibleFiltersCount = Object.entries(filterOptions).filter(([_, value]) => value.visible).length;
		return (visibleFiltersCount > 0 && <Badge bg="info" className='ms-1'>{visibleFiltersCount}</Badge>);
	}

	return (
		<Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)} autoClose="outside" className='tickets-filters-dropdown'>
			<Dropdown.Toggle variant="secondary" onClick={() => setShowDropdown(!showDropdown)}><BsFunnel /><VisibleFiltersCount /></Dropdown.Toggle>
			<Dropdown.Menu style={{ fontSize: '14px', minWidth: '300px' }}>
				<Dropdown.Header className={styles.filtersHeader}>
					Filters:
					<Dropdown>
						<Dropdown.Toggle variant="secondary"><BsPlusLg /></Dropdown.Toggle>
						<Dropdown.Menu style={{ fontSize: '14px' }}>
							<Dropdown.Header>Add Filter:</Dropdown.Header>
							{
								Object.entries(filterOptions).map(([filter, value], index) => {
									if (['due_date_range', 'created_at_range'].includes(filter)) return '';
									return !value.visible ? <Dropdown.Item key={index} onClick={() => toggleFilter(filter)}>{value.label}</Dropdown.Item> : ''
								})
							}
						</Dropdown.Menu>
					</Dropdown>
				</Dropdown.Header>
				{
					isLoading ? <Spinner animation="border" /> : <>
						{
							Object.entries(filterOptions).some(([_, value]) => value.visible) ?
								Object.entries(filterOptions).map(([filter, value], index) => {
									return value.visible ?
										<div key={index} className={styles.filterItemContainer}>
											<TicketsFilterItem
												filter={filter}
												value={intermediateFilters[filter]}
												onFilterUpdate={onFilterUpdate}
												setShowDropdown={setShowDropdown}
												data={data}
											/>
											{
												!['due_date_range', 'created_at_range'].includes(filter) &&
												<BsXLg onClick={() => toggleFilter(filter)} />
											}

										</div> : ''
								}) : <div className={styles.noFilterMessage}>No filters applied.</div>
						}
					</>
				}
				<Dropdown.Divider />
				<div className={styles.buttonContainer}>
					<Button variant='secondary' onClick={clearFilters}>Clear All</Button>
					<Button variant='primary' onClick={applyFilters}><BsCheck2Circle /> Apply</Button>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default TicketsFilter
