import React, { useEffect, useState } from 'react'
import { Col, Row } from "react-bootstrap";
import MainContainer from "../../MainContainer";
import Departments from "./Departments";
import Tags from "./Tags";
import TicketsCount from "./TicketsCount";
import TicketsSummary from "./TicketsSummary";
import Trends from "./Trends";
import Tour from "reactour";
import "./TourContainer.css"
import { tourSteps, fakeDashboard } from './TourData';
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard, seenTour } from "../../../redux/actions";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";

const Dashboard = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state?.userReducer.user);
	const dashboardData = useSelector(state => state?.dashboardReducer.dashboard);
	const [showTour, setShowTour] = useState(false);
	const [dashboard, setDashboard] = useState(null);
	const [useSampleData, setUseSampleData] = useState(false);

	useEffect(() => {
		dispatch(fetchDashboard());
	}, [dispatch]);

	useEffect(() => {
		if (user !== undefined) {
			const hasSeenTour = checkSeenTour();
			const hasNoTickets = checkTicketsSum(dashboardData);
			setDashboard(hasNoTickets ? fakeDashboard : dashboardData);
			setUseSampleData(hasNoTickets);
			setShowTour(!hasSeenTour);
		}
	}, [user, dashboardData]);

	const checkSeenTour = () => {
		const seenTour = Cookies.get('seen_tour');
		return +seenTour === 1 || user.seen_tour === 1;
	};

	const checkTicketsSum = (dashboardData) => {
		if (dashboardData === null || Object.keys(dashboardData).length === 0) {
			return false;
		}
		const ticketsSum = Object.values(dashboardData?.dailyTickets || []).reduce((partialSum, a) => partialSum + a, 0) + Object.values(dashboardData?.ticketsCount || []).reduce((partialSum, a) => partialSum + a, 0);
		return ticketsSum === 0;
	};

	const closeTour = () => {
		Cookies.set('seen_tour', 1);
		dispatch(seenTour());
		setShowTour(false);
	};

	return (
		<MainContainer>
			{dashboard ? (
				<>
					<div className={(useSampleData ? "sample-data ": "") + "section-container pb-4"}>
						<div className="tickets-container-dash">
							<TicketsCount useSampleData={useSampleData} ticketsCount={dashboard?.ticketsCount} />
						</div>
						<Row className="pt-2 mb-1 trends-tickets">
							<Trends useSampleData={useSampleData} trends={dashboard} />
						</Row>
						<Row>
							<Col sm={12} lg={6} xl={4} className="mb-3 tickets-filter-table">
								<TicketsSummary useSampleData={useSampleData} tickets={dashboard} />
							</Col>
							<Col sm={12} lg={6} xl={4} className="mb-3 tickets-largest-departments">
								<Departments useSampleData={useSampleData} departments={dashboard} />
							</Col>
							<Col sm={12} lg={6} xl={4} className="mb-3 tickets-top-tags">
								<Tags useSampleData={useSampleData} tags={dashboard} />
							</Col>
						</Row>
					</div>
					{user && (
						<Tour
							steps={tourSteps}
							isOpen={showTour}
							onRequestClose={closeTour}
							disableInteraction={true}
							lastStepNextButton="Done"
							showButtons={true}
							className="tour-dashboard"
						/>
					)}
				</>
			) : (
				<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 80px)' }}>
					<Loader
						type="TailSpin"
						color="#00BFFF"
						height={100}
						width={100}
						style={{
							animation: 'spin 3s linear infinite'
						}}
					/>
				</div>
			)}
		</MainContainer>
	);
};

export default Dashboard
