import React, { useState, useEffect } from 'react';
import MainContainer from '../MainContainer';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaCopy } from "react-icons/fa";
import { Form, InputGroup, Modal } from 'react-bootstrap';
import successAlert from '../../helpers/successAlert';
import failAlert from '../../helpers/failAlert';
// import { useSelector } from 'react-redux';
import { LuKeyRound } from "react-icons/lu";
import Swal from 'sweetalert2';
// import { RiBookMarkedLine } from "react-icons/ri";
import { GiOpenBook } from "react-icons/gi";
import { NavLink, useParams, useLocation } from 'react-router-dom';
// import { FaRegCopy } from "react-icons/fa";
import { FaHashtag } from "react-icons/fa6";
import { LuBookOpen } from "react-icons/lu";



export function DeveloperOptionsMenu() {
    // const location = useLocation();
    // console.log('location:',location);
    return <div className="col-md-3 p-2 p-md-4">
        <div className="label-header-options mb-3">
            <span>Developer</span>
        </div>
        <div className="list-group">

            <NavLink exact to="/developer" className="list-group-item list-group-item-action" activeClassName="active">
                <LuKeyRound size={30} />
                <span className="ms-2">API keys</span>
            </NavLink>

            <NavLink to="/developer/docs" className="list-group-item list-group-item-action" activeClassName="active">
                <LuBookOpen size={30} />
                <span className="ms-2">Docs</span>
            </NavLink>

        </div>
    </div>
}



function DeveloperOptionKeys() {

    const [keys, setKeys] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleFetchKeys();
    }, []);


    function handleFetchKeys() {
        setLoading(true);
        fetchAllKeys().then((data) => {
            data.map((key) => {
                return key;
            });

            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setKeys(data);
            setLoading(false);
        });
    }


    function handleChangeStatus(row, checked) {
        updateStatusKey(row.key, checked).then((data) => {
            console.log(data);
        });
    }

    function handleDelete(row) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this API key',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                deleteKey(row.key).then((data) => {
                    console.log(data);
                    setKeys(keys.filter((key) => key.key !== row.key));
                    setLoading(false);
                });
            }
        });
    }


    function copyKeyToClipboard(row) {
        navigator.clipboard.writeText(row?.key).then(() => {
            successAlert('Key copied to clipboard');
        }).catch((error) => {
            failAlert('Failed to copy key to clipboard');
        });
    }

    function generateKey() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to create a new API key',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: 'post',
                    url: '/api/dev-options',
                    headers: {
                        Authorization: 'Bearer ' + Cookies.get('access_token')
                    }
                }).then((response) => {
                    if (response.data.success) {
                        successAlert('Key generated successfully');
                        handleFetchKeys();
                    } else {
                        failAlert('Failed to generate key');
                    }
                }
                ).catch((error) => {
                    failAlert('Failed to generate key');
                }
                );
            }
        });
    }

    return <>
        <div className="label-header-options d-flex justify-content-between">
            <span>API keys</span>
            <button onClick={generateKey} className="btn btn-primary">
                + Create new
            </button>
        </div>

        <p className='my-3'>
            Your API requests are authenticated using the account's API key.
            If you do not include your key when making an API request or use incorrect one, we return an error
        </p>

        <table className="table table-center-td">
            <thead>
                <tr>
                    <th style={{ width: 0 }}>Status</th>
                    <th style={{ width: 'auto' }}>Private KEY</th>
                    <th>Created By</th>
                    <th style={{ width: 0 }}>Created at</th>
                    <th style={{ width: 0 }}>Usage</th>
                    <th style={{ width: 0 }}>Actions</th>
                </tr>
            </thead>
            <tbody>

                {loading && (
                    <tr>
                        <td colSpan={6}>
                            <span className="justify-content-center align-items-center d-flex">
                                <div className="spinner-border me-2"></div>
                                <div>Fetching<div className="loading-dots"></div></div>
                            </span>
                        </td>
                    </tr>
                )}

                {(!loading && keys.length === 0) && (
                    <tr><td className="text-center" colSpan={6}>No Result</td></tr>
                )}

                {(!loading && keys.length > 0) && (
                    keys.map((row, index) => (
                        <tr key={row.key}>
                            <td>
                                <Form.Check role="button" onChange={(e) => handleChangeStatus(row, e.target.checked)} defaultChecked={row.enabled} style={{ fontSize: '1.4rem' }} type="switch" id={`switch-${index}`} />
                            </td>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        value={row.key}
                                        readOnly
                                    />
                                    <InputGroup.Text onClick={() => copyKeyToClipboard(row)} role="button">
                                        <FaCopy />
                                    </InputGroup.Text>
                                </InputGroup>
                            </td>
                            <td>{row.user.name}</td>
                            <td className="keep-all-text">{new Date(row.created_at).toLocaleString()}</td>
                            <td>{row.usage_count}</td>
                            <td>
                                <button onClick={() => handleDelete(row)} className="btn btn-sm btn-outline-danger">Delete</button>
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    </>

}

function DeveloperOptions() {
    const { part } = useParams();
    return (
        <MainContainer>
            <div className="container-flex-text-left-options">
                <DeveloperOptionsMenu />
                <div className="col-md-9 bg-white p-2 p-md-4">
                    {part == null && <DeveloperOptionKeys />}
                    {part == 'docs' && <DeveloperOptionsDocs />}
                </div>
            </div>
        </MainContainer>
    );
}


function DeveloperOptionsDocs() {



    return (
        <div>
            <ApiDocumentation
                title="Fetch tickets"
                endpoint="/v1/tickets"
                method="GET"
                description="This endpoint retrieves a list of tickets. Each ticket contains information including ID, ticket ID, user, title, description, department, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key. "
                parameters="None"
                successResponse={`{
    "success": true,
    "tickets": [
        {
            "id": int,
            "ticket_id": string,
            "user": object,
            "title": string,
            "description": string,
            "department": object,
            ...
        }
    ],
    "total": int
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}
            />



            <ApiDocumentation
                title="Fetch single Ticket"
                endpoint="/v1/ticket/{ticket_id}"
                method="GET"
                description="This endpoint retrieves a single ticket. Ticket contains information including ID, ticket ID, user, title, description, department and other attributes."
                auth="This endpoint require header 'Authorization' and the key."
                parameters="None"
                successResponse={`{
    "data":{
        "id": integer,
        "ticket_id": string,
        "created_by": object,
        "title": string,
        "description": string,
        "user": object,
        "department": object,
        "status": object,
        "status": object,
        "priority": object,
        "tags": array,
        ...
    }
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}
            />

            <ApiDocumentation
                title="Create Ticket"
                endpoint="/v1/tickets"
                method="POST"
                description="This endpoint creates a new ticket. The request should include the title, description, department, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key. The key should always start with 'KEY_' and end with '_0'"
                parameters={`{
    "title": "string", // required *
    "description": "string", // required *

    "status_id" : "integer", // optional
    "department_id": "integer", // optional
    "type_id": "integer", // optional
    "user_id": "integer", // optional
    "priority_id": "integer", // optional
    "due_date: "date", // optional
    "tags": "array", // optional
    "attachments": "array", // optional
}`}
                successResponse={`{
    "success": true,
    "message": "Ticket created successfully",
    "ticket": object
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}
            />

            {/* /v1/departments */}
            <ApiDocumentation
                title="Fetch Departments"
                endpoint="/v1/departments"
                method="GET"
                description="This endpoint retrieves a list of departments. Each department contains information including ID, name, description, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key."
                parameters="None"
                successResponse={`{
    "data": [
        {
            "id": "integer",
            "name": "string",
            "description": "string",
            "color": "string",
            "members": array,
            "workspace_id": "integer",
            "users_count": "integer",
            "emails_count": "integer",
            "tickets_count": "integer",
            "created_at": "string",
            "updated_at": "string"
        }
    ]
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}
            />

            <ApiDocumentation
                title="Fetch Tags"
                endpoint="/v1/tags"
                method="GET"
                description="This endpoint retrieves a list of tags. Each tag contains information including ID, name, description, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key."
                parameters="None"
                successResponse={`{
    "data": array
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}/>

            <ApiDocumentation
                title="Fetch Ticket Types"
                endpoint="/v1/ticket-types"
                method="GET"
                description="This endpoint retrieves a list of ticket types. Each ticket type contains information including ID, name, description, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key. "
                parameters="None"
                successResponse={`{
    "data": array
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}/>

{/* GET /v1/statuses */}
            <ApiDocumentation
                title="Fetch Statuses"
                endpoint="/v1/statuses"
                method="GET"
                description="This endpoint retrieves a list of statuses. Each status contains information including ID, name, description, and other attributes."
                auth="This endpoint requires the 'Authorization' header and a key."
                parameters="None"
                successResponse={`{
    "data": array
}`}
                errorResponse={`{
    "success": false,
    "message": "Error message"
}`}/>




        </div>
    );
}

function ApiDocumentation({ title, endpoint, method, description, auth, parameters, successResponse, errorResponse }) {
    const baseURL = 'https://claritick.com/api/dev';
    function copyURLToClipboard(url) {
        if (!url.startsWith('http')) {
            url = url.replace(/^\/|\/$/g, '');
            url = baseURL + '/' + url;
        }
        url = url.trim();
        navigator.clipboard.writeText(url).then(() => {
            successAlert('URL copied to clipboard');
        }).catch((error) => {
            failAlert('Failed to copy URL to clipboard');
        });
    }

    return (
        <div>
            <hr />

            <div id={'doc' + title.replace(' ', '-')} className="d-flex justify-content-between">
                <h4>
                    <FaHashtag onClick={() => {
                        copyURLToClipboard(window.location.href + '#doc' + title.replace(' ', '-'));
                    }} role="button" className="me-1" />
                    {title}
                </h4>
                <h4>API Documentation</h4>
            </div>
            <p><code>{baseURL + endpoint}</code></p>
            <h5>
                <FaCopy role="button" className="copy-developer-option-endpoint" onClick={() => copyURLToClipboard(baseURL + endpoint)} />
                Endpoint: {method}{" "}<b>{endpoint}</b></h5>
            <p>{description}</p>

            <h5>Authorization</h5>
            <p>{auth}</p>

            <h5>Parameters</h5>
            <pre><code>{parameters}</code></pre>

            <h5>Success Response</h5>
            <ul>
                <li><strong>Content:</strong>
                    <pre><code>{successResponse}</code></pre>
                </li>
            </ul>

            <h5>Error Response</h5>
            <ul>
                <li><strong>Content:</strong>
                    <pre><code>{errorResponse}</code></pre>
                </li>
            </ul>
        </div>
    );
}



async function fetchAllKeys() {
    return await axios({
        method: 'get',
        url: '/api/dev-options',
        headers: {
            'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
    }).then((response) => {
        console.log(response.data);
        return response?.data?.success ? response?.data?.data : [];
    }).catch((error) => {
        console.log(error);
        return [];
    });
}

async function updateStatusKey(key_id, status) {
    return await axios({
        method: 'patch',
        url: '/api/dev-options/' + key_id,
        data: {
            enabled: status
        },
        headers: {
            'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
    }).then((response) => {
        console.log(response.data);
        return response?.data?.success;
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

async function deleteKey(key_id) {
    return await axios({
        method: 'delete',
        url: '/api/dev-options/' + key_id,
        headers: {
            'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
    }).then((response) => {
        console.log(response.data);
        return response?.data?.success;
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export default DeveloperOptions
