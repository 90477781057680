import React, { useEffect, useState, } from 'react';
import { Table, Pagination, Spinner, Form } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { FaCaretDown, FaCaretUp, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import ColorBullet from '../../ColorBullet';
import UserBullet from '../../UserBullet';
import styles from './TicketsTable.module.css';

const TicketsTable = ({ tickets, total, columns, sort, pagination, selection, isLoading, onSortChange, onPaginationChange, onSelectionChange }) => {

	const unSortableColumns = ['description', 'tags'];
	const [isAllSelected, setIsAllSelected] = useState(false);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(total / pagination.perPage); i++) {
		pageNumbers.push(
			<Pagination.Item key={i} active={i === pagination.page} onClick={() => onPaginationChange(i, pagination.perPage)} activeLabel="">
				{i}
			</Pagination.Item>
		);
	}

	const setSortIcon = (by) => {
		return sort.by === by ? (sort.direction === 'asc' ? <FaCaretUp /> : <FaCaretDown />) : null;
	}

	const requestSort = (by) => {
		if (!sort.by || unSortableColumns.includes(sort.by)) {
			return
		}
		onSortChange(by)
	}

	const handleSelectAll = (e) => {
		const checked = e.target.checked;
		const IDs = tickets.filter(ticket => !ticket.deleted).map(ticket => ticket.id);
		onSelectionChange(IDs, checked);
	}

	const handleSelectSingle = (id, checked) => {
		onSelectionChange([id], checked);
	}

	useEffect(() => {
		setIsAllSelected(tickets && tickets.every(ticket => selection.includes(ticket.id)));
	}, [tickets, selection])

	return (
		isLoading ? (
			<div className="labels-container-div justify-content-center">
				<Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
			</div>
		) :
			tickets === undefined || tickets.length === 0 ? (
				<div className="labels-container-div justify-content-center">No Tickets Found</div>
			) : (
				<Table className={styles.table} hover>
					<thead>
						<tr>
							<th>
								<Form.Check checked={isAllSelected} onChange={handleSelectAll} />
							</th>
							{
								Object.entries(columns).map(([column, value], index) => {
									return (!unSortableColumns.includes(column) && value.visible) && <th key={index} className={unSortableColumns.includes(column) ? "" : "cursor-pointer"} onClick={() => requestSort(column)}>{value.label}{setSortIcon(column)}</th>
								})
							}
						</tr>
					</thead>
					<tbody>
						{tickets.map((ticket) => (
							<tr key={ticket.id}>
								<td>
									<Form.Check
										disabled={ticket.deleted}
										checked={selection.includes(ticket.id)}
										onChange={(e) => handleSelectSingle(ticket.id, e.target.checked)}
									/>
								</td>
								{columns.title.visible && <td>
									<Link className={styles.link} to={`/ticket/${ticket.id}`}>
										<div className={styles.title}>{ticket.title}</div>
										<div className={styles.description}>
											{columns.tags.visible && <span>{ticket.tags.map((tag, index) => (
												<span key={index} className="badge" style={{ backgroundColor: tag.color }}>{tag.name}</span>
											))}</span>}
											{ticket.description}
										</div>
									</Link>
								</td>}
								{columns.status.visible && <td>
									{ticket.status && <ColorBullet color={ticket.status.color} text={ticket.status.name} />}
								</td>}
								{columns.department.visible && <td>
									{ticket.department && <ColorBullet color={ticket.department.color} text={ticket.department.name} />}
								</td>}
								{columns.type.visible && <td>
									{ticket.type && <ColorBullet color={ticket.type.color} text={ticket.type.name} />}
								</td>}
								{columns.priority.visible && <td>
									{ticket.priority && <ColorBullet color={ticket.priority.color} text={ticket.priority.name} />}
								</td>}
								{columns.user.visible && <td>
									<UserBullet user={ticket.user} />
								</td>}
								{columns.replies.visible && <td>{ticket.replies}</td>}
								{columns.replied_at.visible && <td>{ticket.replied_at ? ticket.replied_at : '-'}</td>}
								{columns.due_date.visible && <td>{ticket.due_date ? ticket.due_date : '-'}</td>}
								{columns.created_at.visible && <td>{ticket.created_at ? ticket.created_at : '-'}</td>}
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={Object.entries(columns).filter(([key, value]) => !unSortableColumns.includes(key) && value.visible).length + 1}>
								<div className="d-flex align-items-center">
									<Pagination className={styles.pagination}>{pageNumbers}</Pagination>
									<label htmlFor="perPageSelect" className={styles.perPageLabel}>Page Size:</label>
									<Form.Control
										as="select"
										id="perPageSelect"
										value={pagination.perPage}
										onChange={(e) => onPaginationChange(1, parseInt(e.target.value))}
										className={styles.perPage}
									>
										{[10, 20, 50, 100].map((size) => (
											<option key={size} value={size}>
												{size}
											</option>
										))}
									</Form.Control>
									<span className={styles.results}>Showing {tickets.length} of {total} Tickets</span>
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			)
	)
}

export default TicketsTable
