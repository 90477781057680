import React, { useEffect, useRef, useState } from 'react'
import { InputGroup, Modal, Form, Dropdown } from 'react-bootstrap';
import { TiFlowMerge } from 'react-icons/ti';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FcSearch } from "react-icons/fc";
import { BsDiagram2 } from 'react-icons/bs';
/*

[
    {
        "id": 56,
        "ticket_id": "3377727f89cd",
        "user": null,
        "title": "qwewqe1",
        "description": "qweqweqwe2",
        "department": {
            "name": "Customer Support",
            "color": "#8D6E63"
        },
        "status": {
            "name": "Active",
            "color": "#79e275"
        },
        "priority": {
            "name": "High",
            "color": "#FFE082"
        },
        "type": null,
        "tags": [],
        "due_date": null,
        "created_at": "1h ago",
        "updated_at": null,
        "replied_at": null,
        "replies": 0,
        "deleted": false
    },
    ...
]
*/
function MergeView({ ticket, onComplete }) {
    const [show, setShow] = useState(false);
    const [ticketsList, setTicketsList] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (!show) { return; }
        handleSearch();
        return () => {
            setTicketsList([]);
        }
    }, [show]);

    function handleSearch(searchValue = '') {
        setIsLoading(true);
        fetchTickets({ exclude: ticket?.id, text: searchValue }).then((data) => {
            setTicketsList(data);
            setIsLoading(false);
        });
    }

    function handleShowModalMerge() {
        setShow(true);
    }

    function handleChangeSelectedTicket(v) {
        setSelectedTicket(v);
    }


    function handleSubmitMerge() {
        if (!selectedTicket) {
            Swal.fire({
                title: 'Error!',
                text: 'Please select a ticket to merge',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
            });
            return;
        }
        // swal loading
        Swal.fire({
            title: 'Loading...',
            text: 'Please wait...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        requestMergeTicket(ticket.id, selectedTicket).then((data) => {
            Swal.close();
            Swal.fire({
                title: data?.success ? 'Success!' : 'Error!',
                text: data?.message,
                icon: data?.success ? 'success' : 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                timer: 5000
            }).then((r)=>{
                if(data.success){
                    onComplete();
                    handleClose();
                }
            });
        }).catch((error) => {
            Swal.close();
            Swal.fire({
                title: 'Error!',
                text: error?.response || 'There was an error merging the tickets',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
            });
        });
    }





    return (
        <>
            <Dropdown.Item onClick={handleShowModalMerge}>
                    <BsDiagram2 />
                    <span>Merge</span>
            </Dropdown.Item>

            <Modal scrollable size="lg" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Merge Tickets</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <InputGroup style={{ width: "unset" }}  >
                        <InputGroup.Text>
                            <FcSearch size={25} />
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="Search by name or ID"
                            onChange={(e) => {
                                handleSearch(e.target.value);
                            }}
                        />
                        <InputGroup.Text>
                            <div className="small">Found: <b>{ticketsList.length}</b></div>
                        </InputGroup.Text>
                    </InputGroup>

                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: "1%" }}>#</th>
                                    <th scope="col" style={{ width: "1%" }}>ID</th>
                                    <th scope="col">Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketsList.map((ticket) => (
                                    <tr className="hover-ticket-cursor-p" onClick={() => { handleChangeSelectedTicket(ticket?.id) }} key={'t_' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}>
                                        <td>
                                            <input id={'t_' + ticket.id} className="style-checkbox-holder-ticket" type="checkbox" name="selectedTicket" defaultChecked={ticket?.id == selectedTicket} />
                                            <label ></label>
                                        </td>
                                        <td>{ticket.id}</td>
                                        <td>{ticket.title}</td>
                                    </tr>
                                ))}
                                {isLoading && <>
                                    <tr>
                                        <td colSpan="3">
                                            <div className="text-center">Loading...</div>
                                        </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <button className="btn btn-primary" onClick={handleSubmitMerge}>Merge</button>
                    <button className="btn btn-secondary" onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

async function fetchTickets({ exclude, text = '' }) {

    exclude = Array.isArray(exclude) ? exclude : [exclude];
    const response = await axios.get(`/api/tickets?exclude=${exclude.join(',')}&text=${text}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    });
    return response?.data?.tickets ?? [];
}



async function requestMergeTicket(ticket_id, ticket_id_to_merge) {
    const response = await axios.post(`/api/merge-tickets`, {
        primary: ticket_id,
        secondary: ticket_id_to_merge
    }, {
        headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    });
    return response?.data;
}



export default MergeView
